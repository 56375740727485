import { isValidPhoneNumber } from 'libphonenumber-js';

export const validateEmail = (value:string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regex.test(value);
};

export const validatePhone = (value:string) => {
  return isValidPhoneNumber(value, 'US');
};

export const validateSsn = (value:string) => {
  // The next line prevents test SSNs
  // const regex = /^[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  const regex = /^[0-9]{3}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  return regex.test(value);
};

export const validateDob = (value:string) => {
  const regex = /^(?:0[1-9]|1[012])([\/.-])(?:0[1-9]|[12]\d|3[01])\1(?:19|20)\d\d$/; // eslint-disable-line
  return regex.test(value);
};
